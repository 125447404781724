import React, { SyntheticEvent } from "react";
import { RavenTableRow, RavenToolTip } from "@ravenpay/raven-bank-ui";
import {
  IconVault,
  capitalizeFirstLetter,
  formatDateTime,
  formatNumWithComma,
  formatStatus,
  symbol,
  trimLongString,
} from "@/utils/helper/Helper";
import { icons } from "@/assets/icons";
import Avatar from "@/components/common/avatar";
import Redacted from "@/components/common/shimmer/redact";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import "./styles/index.css";
import { CopyText } from "@/components/common/copyCheck";
import { cleanWhiteSpace, cn } from "@/utils/colorConvert";
import Dropdown from "@/components/common/dropdown";
import { XStack } from "@/components/common/stacks";
import { autoBillersLogo } from "@/constants/auto-billers-logo";

const imagePlaceholder = IconVault(icons.electricity_placeholder);

interface CardTypeProps {
  status: string;
}

// const cardTypes = ["Funding", "Purchase", "Refund", "Charge Back"];

const CardType = ({ status }: CardTypeProps) => {
  const cardStatus = (() => {
    switch (status?.toLowerCase()) {
      case "funding":
        return "successful";
      case "purchase":
        return "pending";
      case "refund":
        return "refunded";
      case "charge back":
        return "inreview";
      default:
        return "deactivated";
    }
  })();

  return (
    <div className={cn("bugiss-status", cardStatus)}>
      <p>{status}</p>
    </div>
  );
};

export interface BillsType {
  id: string;
  biller: string;
  amount: number;
  date: Date;
  status: string;
  phone: string;
}

export interface TransactionProp {
  direction: "credit" | "debit";
  reference_id: string;
  type: string;
  description: string;
  date: Date;
  amount: string;
  status: string; // You might want to specify a more specific type for `status`
}

const cardDropdown = [
  {
    label: `View details`,
    value: "view",
    img: IconVault(icons.arrow_circle_right),
    // func: showVerifyModal,
  },
  {
    label: `Freeze Card`,
    value: "freeze",
    img: IconVault(icons.snowflakes),
    // func: showVerifyModal,
  },
  {
    label: `Destroy Card`,
    value: "destroy",
    img: IconVault(icons.trash_03),
    // func: showVerifyModal,
  },
] as const;

class BusinessUsersTables {
  type: string;
  trx?: Partial<BusinessTransactionData[]>;
  meta?: any;
  constructor({
    type,
    trx,
  }: {
    type: string | undefined;
    trx?: Partial<BusinessTransactionData[]>;
  }) {
    this.type = type || "transactions";
    this.trx = [];
  }

  Bills = ({
    content,
    loading,
    onRowClick,
  }: {
    content: BusinessAirtimeModel;
    loading?: boolean;
    onRowClick?: Function;
  }) => {
    // const meta = JSON.parse(content.meta_data);
    const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
      event.currentTarget.src = imagePlaceholder;
    };

    return (
      <RavenTableRow
        onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={loading ? <Redacted.Table /> : Util.safeValue(content.trx_ref as string)}
        two={
          loading ? (
            <Redacted.Avatar />
          ) : (
            <>
              <figure className="biller-logo">
                <Avatar
                  onError={addImageFallback}
                  imgSrc={
                    autoBillersLogo(
                      Util.safeValue(
                        content?.network?.toLowerCase() ||
                          (content?.provider?.toLowerCase() as unknown as string)
                      )
                    ) as string
                  }
                  full
                  name={Util.safeValue(
                    capitalizeFirstLetter(
                      content.network || (content.provider as unknown as string)
                    )
                  )}
                />
              </figure>
            </>
          )
        }
        three={
          loading ? (
            <Redacted.Table />
          ) : (
            symbol("ngn") + formatNumWithComma(content.amount, "ngn")
          )
        }
        five={loading ? <Redacted.Table /> : formatDateTime(content.created_at)}
        six={
          loading ? (
            <Redacted.Table />
          ) : (
            formatStatus(detectStatus(Number(content.status)))
          )
        }
        four={loading ? <Redacted.Table /> : Util.safeValue(content?.phone)}
      />
    );
  };

  Cards = ({
    content,
    loading,
    onRowClick,
    onItemClick,
    allChecked,
    onCheck,
    checkValue,
  }: {
    content: BusinessCardModel;
    loading?: boolean;
    allChecked?: boolean;
    onItemClick?: Function;
    onRowClick?: Function;
    onCheck?: Function;
    checkValue: string[];
  }) => {
    // const meta = JSON.parse(content.meta_data);

    return (
      <RavenTableRow
        onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        // key={status}

        one={
          loading ? (
            <Redacted.Table />
          ) : (
            <XStack gap={1} style={{ alignItems: "center" }}>
              {/* <div style={{ opacity: loading ? 0 : 1 }}>
                <RavenCheckBox
                  id={Number(content?.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onCheck &&
                        onCheck({
                          state: true,
                          id: content.id,
                        });
                    } else {
                      onCheck && onCheck({ state: false, id: content.id });
                    }
                  }}
                  color="black-light"
                  checked={
                    allChecked
                      ? allChecked
                      : checkValue.includes((Number(content.id) as any) || allChecked)
                  }
                />
              </div> */}
              {Util.safeValue(content?.main_card_object?.card_name)}
            </XStack>
          )
        }
        two={
          loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={content?.main_card_object?.card_number} />
          )
        }
        three={loading ? <Redacted.Table /> : Util.safeValue(content?.currency)}
        four={
          loading ? (
            <Redacted.Table />
          ) : (
            formatNumberToCurrency(content?.main_card_object?.balance)
          )
        }
        five={
          loading ? (
            <Redacted.Table />
          ) : (
            Util.join(Util.safeValue(content?.main_card_object?.expiry_month)).with(
              `/${Util.safeValue(content?.main_card_object?.expiry_year)}`
            )
          )
        }
        six={loading ? <Redacted.Table /> : formatDateTime(content?.created_at)}
        seven={
          loading ? (
            <Redacted.Avatar />
          ) : (
            <Dropdown
              rightPosition
              items={cardDropdown}
              defaultValue={cardDropdown[0]}
              onChange={(e: any) => {
                e.event.stopPropagation();
                onItemClick && onItemClick({ action: e.value, data: content });
              }}
              className="ragna-dropdown"
              buttonChild={({ selected }) => <ActionDrop more />}
            />
          )
        }

        // three={renderRedacted("Table Body Content", "Table")}
        // four={renderRedacted(
        //   formatNumberToCurrency(assertPaginationPageNumber("2000")),
        //   "Table"
        // )}
        // six={renderRedacted("xxx", "Table")}
        // five={renderRedacted(<CardType status={cardTypes[idx]} />, "Table")}
        // seven={renderRedacted(formatStatus(status), "Table")}
      />
    );
  };

  Transactions = ({
    content,
    loading,
    onRowClick,
  }: {
    content: BusinessTransactionData;
    loading?: boolean;
    onRowClick?: () => void;
  }) => {
    const meta = JSON.parse(content.meta_data);

    return (
      <RavenTableRow
        onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          loading ? (
            <Redacted.AvatarWithValue />
          ) : (
            <div className="table-icon-row">
              <figure>
                {content.direction === "credit" ? icons.credit : icons.debit}
              </figure>
              {
                <CopyText
                  text={Util.safeValue(
                    trimLongString(
                      content?.trx_ref ??
                        content?.bulk_transfer_reference ??
                        meta?.session_id ??
                        "--",
                      15
                    )
                  )}
                  textToCopy={
                    content?.trx_ref ??
                    content?.bulk_transfer_reference ??
                    meta?.session_id ??
                    "--"
                  }
                />
              }
            </div>
          )
        }
        two={<>{loading ? <Redacted.Table /> : Util.cleanText(content.type)}</>}
        three={
          <>
            {loading ? (
              <Redacted.Table />
            ) : (
              <RavenToolTip color={"purple-dark"} position="top-center">
                {React.cloneElement(<p>{Util.safeText(trimLongString(Util.cleanText(content.narration), 30))}</p>, {
                  style: { color: "white" }
                })
                }
              </RavenToolTip>
            )}
          </>
        }
        four={
          <>{loading ? <Redacted.Table /> : formatDateTime(content.created_at ?? "")}</>
        }
        five={
          <>
            {loading ? (
              <Redacted.Table />
            ) : (
              symbol("ngn") + formatNumWithComma(content.amount, "ngn")
            )}
          </>
        }
        six={
          loading ? (
            <Redacted.Badge />
          ) : (
            formatStatus(detectStatus(Number(content.status)))
          )
        }
      />
    );
  };

  Transfers = ({
    content,
    loading,
    onRowClick,
  }: {
    content: BusinessSingleTrxModel;
    loading?: boolean;
    onRowClick?: Function;
  }) => {
    const meta = JSON.parse(content.meta_data);

    return (
      <RavenTableRow
        onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              <CopyText
                text={Util.safeValue(
                  trimLongString(content?.trx_ref ?? meta?.session_id ?? "--", 15)
                )}
                textToCopy={content?.trx_ref ?? meta?.session_id ?? "--"}
              />
            )}
          </div>
        }
        two={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.AvatarWithValue />
            ) : (
              <>
                {" "}
                <Avatar name={Util.safeValue(content.account_name)} />
                {Util.safeValue(content.account_name)}
              </>
            )}
          </div>
        }
        three={loading ? <Redacted.Table /> : Util.safeValue(content?.bank)}
        four={loading ? <Redacted.Table /> : Util.safeValue(content?.account_number)}
        five={
          loading ? (
            <Redacted.Table />
          ) : (
            <>{symbol("ngn") + formatNumWithComma(content.amount, "ngn")}</>
          )
        }
        six={
          loading ? <Redacted.Table /> : <>{formatDateTime(content.created_at ?? "--")}</>
        }
        seven={
          loading ? (
            <Redacted.Badge />
          ) : (
            formatStatus(detectStatus(Number(content.status), "transactions"))
          )
        }
      />
    );
  };

  InternalTransfer = ({
    content,
    loading,
    onRowClick,
  }: {
    content: BusinessSingleTrxModel;
    loading?: boolean;
    onRowClick?: Function;
  }) => {
    const meta = JSON.parse(content.meta_data);

    return (
      <RavenTableRow
        onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              <CopyText
                text={Util.safeValue(
                  trimLongString(content?.trx_ref ?? meta?.session_id ?? "--", 15)
                )}
                textToCopy={content?.trx_ref ?? meta?.session_id ?? "--"}
              />
            )}
          </div>
        }
        two={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.AvatarWithValue />
            ) : (
              <>
                {" "}
                <Avatar name={Util.safeValue(content.account_name)} />
                {Util.safeValue(content.account_name)}
              </>
            )}
          </div>
        }
        three={loading ? <Redacted.Table /> : Util.safeValue(content?.bank)}
        four={
          loading ? (
            <Redacted.Table />
          ) : (
            Util.safeValue(cleanWhiteSpace("@", content?.identifier))
          )
        }
        five={
          loading ? (
            <Redacted.Table />
          ) : (
            <>{symbol("ngn") + formatNumWithComma(content.amount, "ngn")}</>
          )
        }
        six={
          loading ? <Redacted.Table /> : <>{formatDateTime(content.created_at ?? "--")}</>
        }
        seven={
          loading ? (
            <Redacted.Badge />
          ) : (
            formatStatus(detectStatus(Number(content.status), "transactions"))
          )
        }
      />
    );
  };

  BulkTransfer = ({
    content,
    loading,
    onRowClick,
    onMoreClick,
  }: {
    content: BusinessBulkTrxModel;
    loading?: boolean;
    onRowClick?: Function;
    onMoreClick?: Function;
  }) => {
    const meta = JSON.parse(content.meta_data);
    // const summary = formatMetaData(meta?.summary ?? "");

    const summary: any = meta?.summary ?? {};

    return (
      <RavenTableRow
        // onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              trimLongString(content.bulk_transfer_reference ?? "--", 15)
            )}
          </div>
        }
        two={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              Util.safeValue(symbol("ngn") + formatNumWithComma(content.amount))
            )}
          </div>
        }
        three={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              Util.safeValue(formatNumWithComma(summary?.total_beneficiaries))
            )}
          </div>
        }
        four={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              Util.safeValue(formatNumWithComma(summary?.total_no_sent))
            )}
          </div>
        }
        five={
          <div className="table-icon-row">
            {loading ? <Redacted.Table /> : Util.safeValue(formatNumWithComma(0, true))}
          </div>
        }
        six={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              Util.safeValue(formatNumWithComma(summary?.total_no_failed))
            )}
          </div>
        }
        seven={
          <div className="table-icon-row">
            {loading ? (
              <Redacted.Table />
            ) : (
              Util.safeValue(symbol("ngn") + formatNumWithComma(content.fee))
            )}
          </div>
        }
        eight={
          loading ? <Redacted.Table /> : <>{formatDateTime(content.created_at ?? "--")}</>
        }
        nine={
          loading ? (
            <Redacted.Avatar />
          ) : (
            <ActionDrop visit onVisitClick={(e) => onMoreClick && onMoreClick()} />
          )
        }
      />
    );
  };
}

export default BusinessUsersTables;
