import { PbTransactions } from "~pb/transactions";
import { PersonalResolutionCenter } from "~pb/resolution-center";
import { PersonalDispute } from "~pb/dispute";
import { personal_nested_routes } from "./nested-routes";
import { PBRewards } from "@/apps/personal/rewards";
import PersonalAuditTrail from "@/apps/personal/audit-trail";
import PersonalNotification from "@/apps/personal/notification";
import { PbTransactionsMonitoringPage } from "~pb/transactions/transaction-monitoring";

export const personal_routes_group: RouteArray[] = [
  { path: "/personal-transactions", element:PbTransactions},
  { path: "/personal-transactions/all-transactions", element: PbTransactions},
  { path: "/personal-transactions/flagged-transaction", element: PbTransactionsMonitoringPage},
  { path: "/personal-resolution-center", element: PersonalResolutionCenter },
  { path: "/personal-dispute", element: PersonalDispute },
  { path: "/personal-rewards", element: PBRewards },
  { path: "/personal-audit-trail", element: PersonalAuditTrail },
  { path: "/personal-notification-center", element: PersonalNotification },
  ...personal_nested_routes,
];
