import { icons } from "@/assets/icons";
import { formatStatus, trimLongString } from "@/utils/helper/Helper";
import React from "react";
import "./styles/index.css";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { useNavigate } from "react-router-dom";
const BusinessLinkedBusinesses = ({ data }: { data: LinkedBusiness[] }) => {
  const navigate = useNavigate()
  return (
    <div className="auth-device-container flex col">
      <p style={{ color: "#676767", marginTop: "1rem" }}></p>
      {data?.map((d) => {
        return (
          <div className="auth-device-container__item">
            <div className="auth-device-container__item--title-wrap">
            <AvatarWithTwinValue 
            name={Util.join(d?.business_name).with(d.business_owner_email)}
            one={d?.business_name}
            two={d?.email}
            />
            </div>

            <div onClick={() => {navigate(`/business-businesses/${d.email}`)}} className=" cursor-pointer">
        
              <figure>{icons.arrow_circle_broken}</figure>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BusinessLinkedBusinesses;
