import InlineDetails, { InlineDetailsContent } from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { replaceSymbolInText } from "@/utils/formatWord";
import { formatDateTime, formatMetaData } from "@/utils/helper/Helper";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import TabComponent from "@/components/common/Tabs";

interface Props {
  transaction: PersonalTransactionModel;
  meta?: any
}

export const PersonalTransactionModal = (props: CreateDetailsProps<Props>) => {
  const { onCancel, transaction, meta } = props;

  const { amount, email, reference, description, type, fee, created_at,_to } = transaction;

  const Content: InlineDetailsContent[] = [
    { label: "Amount", value: replaceSymbolInText(amount) },
    { label: "Balance Before", value: formatNumberToCurrency(transaction.b_before ?? 0) },
    { label: "Balance After", value: formatNumberToCurrency(transaction.b_after ?? 0) },
    { label: "Recipient", value: Util.safeText(_to) },
    { label: "Description", value: Util.safeText(description) },
    { label: "Type", value: Util.safeText(type) },
    { label: "Fee", value: fee ? formatNumberToCurrency(fee) : "--" },
    { label: "Reference ID", value: Util.safeText(reference), newCopy: true },
    { label: "Date Logged", value: formatDateTime(created_at) },
  ];

  const parsedMeta = meta && TypeIs.string(meta) && Object.entries(JSON.parse(meta));

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={() => onCancel()}
      title="Transaction Details"
      hideCancel
      btnColor="#F7F7F7"
      btnTextColor="#020202"
      // DISABLING download receipt for now
      actionText="Export as PDF"
      hideNormalButton
      className={cn(GENERAL_CLASSES.BIG_MODAL, "pb-transaction-dt-modal")}
    >

      <TabComponent defaultValue="Details">


        {Array.isArray(parsedMeta) &&  <TabComponent.Tabs
          tabs={['Details', 'Metadata']}
          className="pb-insights-sidebar__tab "
        />}
        {['Details', 'Metadata'].map((n) => (
          <TabComponent.Content
            className=""
            value={n} // Set dynamic value here based on tab name
            key={n}
          >
            {n === 'Details' ? (
              <div className={cn(GENERAL_CLASSES.INLINE_DETAILS.WRAPPER)}>
                <InlineDetails
                  meta={meta}
                  title="Transaction Details"
                  simple
                  className={cn(GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW, "small-padding")}
                  amount={0}
                  content={Content}
                />
              </div>
            ) : (
              <div>
                {/* Render the content for 'MetaData' tab here */}
                <InlineDetails
                  title="Metadata"
                  simple
                  className={cn(GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW, "small-padding")}
                  amount={0}
                  content={(() => {
                    try {
                      // Attempt to parse and check if it's an array
                      if (Array.isArray(parsedMeta)) {
                        // Map each object to { label, value } pairs
                        return parsedMeta.map((d: any) => {
                          const [key, value] = d || {}; // Safely get the first key-value pair or fallback to empty array
                          return {
                            label: Util.cleanText(key) || "", // Default label to an empty string if key is undefined
                            value: value || "" // Default value to an empty string if value is undefined
                          };
                        });
                      } else {
                        console.warn("Parsed meta is not an array");
                        return []; // Return empty array if parsed data is not an array
                      }
                    } catch (error) {
                      console.error("Error parsing meta:", error);
                      return []; // Return empty array if JSON parsing fails
                    }
                  })() as any}                />
              </div>
            )}
          </TabComponent.Content>
        ))}
      </TabComponent>

    </ActionModal>
  );
};
