import { ClickAbleEmail } from "@/components/common/clickable-row";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import { replaceSymbolInText } from "@/utils/formatWord";
import { formatDateTime, formatMetaData, formatStatus, trimLongString } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, RavenToolTip } from "@ravenpay/raven-bank-ui";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import React, { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { GENERAL_CLASSES, STRING_TRIM_LENGTH } from "@/constants";
import { CreateTableRowProps } from "../shared";
import { PersonalTransactionModal } from "./modals/transaction-details-modal";
import {
  PersonalTransactionMonitoringModal
} from "~pb/__internals__/combined-tables/transaction-table/modals/transaction-monitoring-modal";
import { ClearMonitoredTransactionModal } from "~pb/compliance/components/modals";
import { Column, Row } from "@/components/common/stacks";

export const PersonalTransactionTable = (
  props: CreateTableRowProps<{ data: PersonalTransactionModel[] , isVariant?: boolean }>
) => {
  const [selectedRow, setSelectedRow] = useState<PersonalTransactionModel>();
  const { renderSafeModal } = useDynamicModal(selectedRow);
  const { RedactedLabel, RedactedAvatarWithValue, RedactedTable } = props.redactedFn;

  return (
    <RavenTable
      className="table__main"
      headerList={[
        "REFERENCE",
        "DESCRIPTION",
        "USER DETAILS",
        "BAL. BEFORE",
        "BAL. AFTER",
        "AMOUNT",
        "TRANSACTION DATE",
      ]}
    >
      {props?.data?.map((row) => {
        const {
          id,
          direction,
          reference,
          description,
          email,
          type,
          data,
          amount,
          created_at,
          b_after,
          b_before,
          currency,
        } = row;

          const meta = formatMetaData(data as string);


        return (
          <RavenTableRow
            className={GENERAL_CLASSES.OVERRIDE_COPY_STYLE}
            key={id}
            one={RedactedAvatarWithValue(
              <Txreference
                reference={Util.safeValue(reference)}
                direction={direction}
                trim={true}
                trimLength={15}
                hideCopy={!Boolean(reference)}
              />
            )}
            two={RedactedLabel(
              <TwinValue
                className={GENERAL_CLASSES.TOOL_TIP_WRAPPER}
                one={type}
                two={TypeIs.any(
                  <>
                    <RavenToolTip color={"purple-dark"} position="top-center">
                      {React.cloneElement(<p>{Util.safeText(description)}</p>, {
                        style: { color: "white" },
                      })}
                    </RavenToolTip>

                    <p style={{ maxWidth: "220px" }}>
                      {trimLongString(
                        replaceSymbolInText(Util.safeText(description)),
                        25
                      )}
                    </p>
                  </>
                )}
              />
            )}
            three={RedactedTable(
              <ClickAbleEmail email={Util.safeText(email)} trim={STRING_TRIM_LENGTH} />
            )}
            four={RedactedTable(formatNumberToCurrency(b_before, currency))}
            five={RedactedTable(formatNumberToCurrency(b_after, currency))}
            six={RedactedTable(replaceSymbolInText(Util.safeText(amount)))}
            seven={RedactedTable(formatDateTime(created_at))}
            onRowClick={() => setSelectedRow(row)}
          />
        );
      })}

      {renderSafeModal(
        <PersonalTransactionModal
          transaction={TypeIs.safe(selectedRow)}
          onCancel={() => setSelectedRow(undefined)}
          meta={selectedRow?.data}
          ctx={props.ctx}
        />,
        selectedRow
      )}
    </RavenTable>
  );
};


export const PersonalTransactionMonitoringTable = (
  props: CreateTableRowProps<{ data: TransactionMonitored[] , isVariant?: boolean, refetch?: Function }>
) => {
  const [selectedRow, setSelectedRow] = useState<TransactionMonitored>();
  const { renderSafeModal } = useDynamicModal(selectedRow);
  const { RedactedLabel, RedactedAvatarWithValue, RedactedTable } = props.redactedFn;
  const [markChecked, setMarkChecked] = useState<string | null>();
  const { renderSafeModal:renderCheckModal } = useDynamicModal(markChecked);

  return (
    <RavenTable
      className="table__main"
      headerList={[
        "REFERENCE",
        "USER EMAIL",
        "AMOUNT",
        "REASON FOR FLAG",
        "DATE FLAGGED",
        "STATUS"
      ]}
    >
      {props?.data?.map((row) => {
        const {
          id,
          email,
          type,
          status,
          flag_reasons,
          transaction,
          created_at,
          checked

        } = row;

        const {
          direction,
          reference,
          description,
          data,
          amount,
          b_after,
          b_before,
          currency
        } = transaction;

        const reason = formatMetaData(row.flag_reasons as string);

        const reasonTooltip = Array.isArray(reason) ?
          reason.map((r: string, idx: number) => {
            return <RavenToolTip key={idx} color={"purple-dark"} position="top-center">
              {
                <Column>
                  <Row ai={'start'} gap={0.5}>
                    1.
                    {
                      React.cloneElement(<p>{Util.safeText(r)}</p>, {
                        style: { color: "white" }
                      })
                    }
                  </Row>
                  <div></div>
                </Column>
              }
            </RavenToolTip>;
          }) : <RavenToolTip color={"purple-dark"} position="top-center">
            {React.cloneElement(<p>{Util.safeText(reason)}</p>, {
              style: { color: "white" }
            })
            }
          </RavenToolTip>;

        const reasonText =  Array.isArray(reason) ?
        reason.map((r: string, idx: number) => {
          return <div key={idx} color={"purple-dark"}>
            {
              <Column>
                <Row ai={'start'} gap={0.5}>
                  { reason?.length > 1 ? `${idx + 1}.` : ""}
                  {
                    React.cloneElement(<p>{Util.safeText(r)}</p>, {
                      style: { maxWidth: "24rem" }
                    })
                  }
                </Row>
                <div></div>
              </Column>
            }
          </div>;
        }) : <div color={"purple-dark"}>
          {React.cloneElement(<p>{Util.safeText(reason)}</p>, {
            style: { color: "white" }
          })
          }
        </div>;
        return (
          <RavenTableRow
            className={GENERAL_CLASSES.OVERRIDE_COPY_STYLE}
            key={id}
            one={RedactedAvatarWithValue(
              <Txreference
                reference={Util.safeValue(reference)}
                direction={direction}
                trim={true}
                trimLength={15}
                hideCopy={!Boolean(reference)}
              />
            )}

            two={RedactedTable(
              <ClickAbleEmail email={Util.safeText(email)} trim={STRING_TRIM_LENGTH} />
            )}
            three={RedactedLabel(
              <TwinValue
                // className={GENERAL_CLASSES.TOOL_TIP_WRAPPER}
                one={Util.safeText(amount)}
                two={TypeIs.any(
                  <>
                    <RavenToolTip color={"purple-dark"} position="top-center">
                      {React.cloneElement(<p>{Util.safeText(type)}</p>, {
                        style: { color: "white" }
                      })}
                    </RavenToolTip>

                    <p style={{ maxWidth: "220px" }}>
                      {trimLongString(
                        replaceSymbolInText(Util.safeText(Util.cleanText(type))),
                        25
                      )}
                    </p>
                  </>
                )}
              />
            )}
            four={RedactedTable(TypeIs.any(
              <div
                className={GENERAL_CLASSES.TOOL_TIP_WRAPPER}
              >
                {/* {reasonTooltip} */}
                {reasonText}
              </div>
            ))}
            five={RedactedTable(formatDateTime(created_at))}
            six={<div onClick={(e) => {checked === 0 && e.stopPropagation(); setMarkChecked(id?.toString())}}>
              {formatStatus(checked === 1 ? "cleared" : checked === 0 ? "uncleared" : "unknown")}
            </div>}
            onRowClick={() => setSelectedRow({ ...row, flag_reasons: replaceSymbolInText(Util.safeText(Util.cleanText(Array.isArray(reason) ?  reason[0] : reason))) })}
          />
        );
      })}

      {renderSafeModal(
        <PersonalTransactionMonitoringModal
          transaction={TypeIs.safe(selectedRow?.transaction)}
          onCancel={() => setSelectedRow(undefined)}
          onClick={(id:string) => setMarkChecked(id)}
          log={{
            status: selectedRow?.checked === 1 ? "cleared" : selectedRow?.checked === 0 ? "uncleared" : "unknown",
            risk_score: String(selectedRow?.risk_score),
            date: selectedRow?.created_at as string,
            reason: selectedRow?.flag_reasons as string,
            id: String(selectedRow?.id) as string
          }}
          ctx={props.ctx}
         user={
          selectedRow?.user as  TransactionMonitored['user']
         }/>,
        selectedRow
      )}

      {renderCheckModal(
      <ClearMonitoredTransactionModal  id={markChecked as string} onCancel={() => setMarkChecked(null)} refetch={props.refetch ? props?.refetch as ()=> void:  () => {}}/>,
        Boolean(markChecked)
      )}
    </RavenTable>
  );
};
