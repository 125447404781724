import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { EmptyShell } from "@/components/common/shell-layouts";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { CUSTOM_FILTER_VALUE_FOR_ALL, GLOBAL_PER_PAGE } from "@/constants";
import {
  PersonalTransactionMonitoringTable,
  PersonalTransactionTable
} from "~pb/__internals__/combined-tables/transaction-table";
import React, { useEffect, useState } from "react";
import { useExportModal } from "@/hooks/useExportModal";
import { toast } from "@ravenpay/raven-bank-ui";
import {  usePbTransactionsExport } from "~pb/__internals__/internal-hooks/savings";
import TabComponent from "@/components/common/Tabs";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";

const filterOutNullValues = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value != null && value !== ''));
};

export const PbTransactions = () => {
  const pagination = usePagination();


  const { currentPage } = pagination;
  const queryParams = filterOutNullValues({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
    [pagination.filterValue.filterBy as string]: pagination.filterValue.filterValue,
  });
  const Tabs = ["All Transactions", "Flagged Transaction"];

  const { data, isLoading, isFetching } = personalReduxAPI.useGetUserTransactionsQuery(queryParams as any );
  // const [isExporting, setIsExporting] = useState(0);

  const { ExportModal, onExport } = usePbTransactionsExport();

  const { SmartFilterProps } = useSmartFilterProps(mapTransfersPagination(data), pagination);





  const joinedLoader = isLoading || isFetching;
  const redactedFn = renderRedactedComponent(joinedLoader);


  const combinedLoader = isLoading || isFetching

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Transactions">
        <SmartFilter
          alternateTitle=" "
          {...SmartFilterProps}
          onExport={onExport}
          singleSelect
          filters={[
            {
              label: "Direction",
              filterBy: "direction",
              options: [
                { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
                { label: "Credit", value: "credit" },
                { label: "Debit", value: "debit" },
              ],
            },
          ]}
        />

        {(combinedLoader ||  !data) ?
          <EmptyScreen loading={combinedLoader} />

          : <PersonalTransactionTable ctx="page" data={data?.transactions as PersonalTransactionModel[] } redactedFn={redactedFn} />
        }
      </PageLayout>
      <ExportModal/>
    </DashboardLayout>
  );
};