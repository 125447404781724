import SmartFilter from "@/components/common/smartFilter";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { CUSTOM_FILTER_VALUE_FOR_ALL, GLOBAL_PER_PAGE } from "@/constants";
import {
  PersonalTransactionMonitoringTable} from "~pb/__internals__/combined-tables/transaction-table";
import {  usePbTransactionsExport } from "~pb/__internals__/internal-hooks/savings";

const filterOutNullValues = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value != null && value !== ''));
};

export const PbTransactionsMonitoringPage = () => {
  const pagination = usePagination();
  const { currentPage } = pagination;


  const queryParams = filterOutNullValues({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
    [pagination.filterValue.filterBy as string]: pagination.filterValue.filterValue,
  });

  const { data, isLoading, isFetching } = personalReduxAPI.useGetTransactionMonitoringLogQuery(queryParams as any );
  // const [isExporting, setIsExporting] = useState(0);

  const { ExportModal, onExport } = usePbTransactionsExport();

  const { SmartFilterProps } = useSmartFilterProps(mapTransfersPagination(data), pagination);



  const joinedLoader = isLoading || isFetching;
  const redactedFn = renderRedactedComponent(joinedLoader);


  const combinedLoader = isLoading || isFetching

  return (
    <div className={'mt-20'}>
      <SmartFilter
        alternateTitle=" "
        {...SmartFilterProps}
        onExport={onExport}
        singleSelect
        filters={[
          {
            label: "Direction",
            filterBy: "direction",
            options: [
              { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
              { label: "Credit", value: "credit" },
              { label: "Debit", value: "debit" },
            ],
          },
        ]}
      />
      {(combinedLoader ||  !data) ?
        <EmptyScreen loading={combinedLoader} />
        : <PersonalTransactionMonitoringTable ctx="page" data={data?.transactions as TransactionMonitored[]} redactedFn={redactedFn} />
      }
      <ExportModal/>

    </div>

  );
};