import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenActionBar, RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import "./styles/index.css";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString
} from "@/utils/helper/Helper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BugissApi } from "@/redux/slices";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";

import { TabsElement } from "@/components/common/Tabs/shared";
import InlineDetails from "@/components/common/inlineDetails";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import Gap from "@/components/common/styleComponents/Gap";
import { cleanWhiteSpace, cn } from "@/utils/colorConvert";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { GENERAL_CLASSES, REASONS_FOR_BLOCK } from "@/constants";
import { useExportModal as useExportStatement } from "@/hooks/useExportModal";
import AuthorizedDevices from "../authorized_devices";
import BusinessBeneficiary from "../business_beneficiaries";
import BusinessSubaccounts from "../subaccounts";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { OutletTab } from "@/components/common/OutletTab";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import Operators from "../operators";
import { sendRequest } from "@/utils/api-methods";
import { Column, Row } from "@/components/common/stacks";
import { useAppDispatch } from "@/redux/store";
import React from "react";
import BusinessLinkedBusinesses from "../linked-business";

const BusinessSingleBusiness = () => {
  let [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { email } = useParams();
  const bulk_ref = searchParams.get("bulk_ref") ?? undefined;

  const tab: any = "airtime";
  const [bizEmail, setBizEmail] = useState("");
  const [serverBlockReasons, setServerBlockReasons] = useState([]);
  const [lienForm, setLienForm] = useState<Partial<ILienUnlien>>({});
  const [statementFunc, setStatementFunc] = useState<boolean>(false);
  const [deductForm, setDeductForm] = useState<Required<IDeductBusinessModel>>({
    amount: null,
    business_id: "",
    business_account_number: "",
    reason: "",
    user_pin: 0,
  });
  const [blockReason, setBlockReason] = useState<any>({
    reason: "",
    more_details: "",
  });
  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessTransactionData>;
  }>({
    on: false,
    data: {},
  });
  const [biz, setBiz] = useState<Partial<XBusinessesModel["business"]>>({});
  const [centralBiz, setCentralBiz] = useState<
    Partial<XBusinessesModel["business_info_on_central"]>
  >({});
  const [directors, setDirectors] = useState<Partial<SingleDirector[]>>([]);

  const [type, setType] = useState<{
    label: string;
    value: string;
    compoundName?: string;
    isParent?: boolean;
  }>({ label: "Transactions", value: "transactions", compoundName: "transactions" });

  const [modal, setModal] = useState<{
    block?: boolean;
    strip?: boolean;
    cardView?: boolean;
    cardFreeze?: boolean;
    cardDestroy?: boolean;
    beneficiary?: boolean;
    subAccount?: boolean;
    linkedBusiness?: boolean;
    devices?: boolean;
    lien?: boolean;
    deduct?: boolean;
    managers?: boolean;
  }>({
    block: false,
    strip: false,
    lien: false,
    deduct: false,
    cardFreeze: false,
    cardDestroy: false,
    beneficiary: false,
    subAccount: false,
    devices: false,
    cardView: false,
    managers: false,
  });

  const [selectedCard, setSelectedCard] = useState<Partial<BusinessCardModel>>({});

  const Tabs: TabsElement[] = [
    { label: "Transactions", value: "transactions", compoundName: "transactions" },
    {
      label: "Transfers",
      value: "transfer",
      compoundName: "transfer",
      isParent: true,
      dropdown: {
        onChange: (e: { event: Event; label: string; value: string }) => {
          navigate(`${e.value}`);
        },
        tabs: [
          {
            label: "Single Transfer",
            value: "transfer",
            compoundName: "transfer",
          },
          {
            label: "Single Internal Transfers",
            value: "internal-transfer",
            compoundName: "transfer",
          },
          {
            label: "Bulk Transfers",
            value: "bulk-transfer",
            compoundName: "bulk-transfer",
          },
        ],
      },
    },
    {
      label: "Bills",
      value: "bills",
      isParent: true,
      compoundName: "bills",
      dropdown: {
        onChange: (e: { event: Event; label: string; value: string }) => {
          setType(e);
          navigate(`${e.value}`);
        },
        tabs: [
          {
            label: "Airtime Recharge",
            value: "airtime",
            compoundName: "bills",
          },
          { label: "Data Topup", value: "data", compoundName: "bills" },
          { label: "Cable TV", value: "cable", compoundName: "bills" },
          {
            label: "Electricity",
            value: "electricity",
            compoundName: "bills",
          },
          { label: "Betting", value: "betting", compoundName: "bills" },
        ],
      },
    },
    // { label: "Savings", value: "savings", compoundName: "savings" },
    // { label: "Overdraft", value: "overdraft", compoundName: "overdraft" },
    {
      label: "Cards",
      value: "cards",
      // isParent: true,
      compoundName: "cards",
      // dropdown: {
      //   onChange: (e: { event: Event; label: string; value: string }) => {
      //     setType(e);
      //   },
      //   tabs: [
      //     {
      //       label: "Virtual Card Funding",
      //       value: TxType.virtualCardFundingType,
      //       compoundName: "cards",
      //     },
      //     {
      //       label: "Virtual Card Transactions",
      //       value: TxType.virtualCardChargeType,
      //       compoundName: "cards",
      //     },
      //     {
      //       label: "Virtual Card Withdrawal",
      //       value: TxType.virtualCardWithdrawalType,
      //       compoundName: "cards",
      //     },
      //     {
      //       label: "Physical",
      //       value: TxType.physicalCardChargeType,
      //       compoundName: "cards",
      //     },
      //   ],
      // },
    },
    { label: "Compliance", value: "compliance", compoundName: "compliance" },
    {
      label: "Team Members",
      value: `team-members?biz-id=${biz.id}`,
      compoundName: "team-members",
    },
  ];

  interface FormState {
    amount?: SN;
    reason_for_deduction?: string;
    reason_for_block?: string;
    reason?: string;
    user_id?: string;
    user_pin?: string;
  }

  type Errors = Partial<Record<keyof FormState, string>>;

  const [errors, setErrors] = useState<Errors>({});
  const [bankAccounts, setBankAccounts] = useState<any>([]);
  const [actionBar, setActionBar] = useState(biz.block_business === 1 ? true : false);

  useEffect(() => {
    setErrors({});
  }, [deductForm, lienForm]);

  async function fetchData() {
    if (bizEmail) {
      const [businessesResponse] = await Promise.all([
        dispatch(
          BugissApi.business.fetchBusinessDetails({
            per_page: 20,
            business_email: email as string,
            // current_page: currentPage,
            // search_term: searchQuery,
          })
        ),
      ]);

      const resp = await businessesResponse;

      if (resp.payload?.status === "success") {
        setBiz(resp.payload.data.business);
        setActionBar(resp.payload.data.business.block_business === 1 ? true : false);
        setCentralBiz(resp.payload.data.business_info_on_central);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [email, bizEmail]);

  useEffect(() => {
    setBizEmail(email ?? "");
  }, []);

  const { bizLoading, loading } = useSelector(
    (state: RootState) => state.businessBusinesses
  );

  async function handleBlockUnblock() {
    // if (!blockReason?.value) {
    //   return toast.error("Reason for block is required");
    // }
    const resp = await dispatch(
      BugissApi.business.blockUnlockBusiness({
        status: Number(biz?.block_business) === 0 ? "1" : "0",
        reason_for_block: blockReason?.reason?.label,
        more_details: blockReason?.more_details,
        action_to_take: bloc.find((d: { id: any; }) => d.id === blockReason.reason?.value)
          ?.action as string,
        business_id: String(biz.id),
      })
    );

    if (resp.payload?.status === "success") {
      fetchData();

      setModal({
        block: false,
      });
    }
  }

  async function handleLienUnlien() {
    if (!lienForm?.reason || lienForm?.reason?.length < 3) {
      setErrors({ reason: "This field is required" });
      return;
    }
    if (lienForm?.amount === null || Number(lienForm?.amount) < 1) {
      setErrors({ amount: "This field is required" });
      return;
    }
    if (lienForm?.user_pin === null || String(lienForm?.user_pin)?.length !== 6) {
      toast.error("User pin must be 6 characters long");
      return;
    }
    let resp;

    if (biz.lien && biz.lien > 0) {
      resp = await dispatch(
        BugissApi.business.removeLien({
          reason: "blockReason?.value",
          business_id: String(biz.id),
          amount: 500,
          user_pin: "",
        })
      );
    } else {
      resp = await dispatch(
        BugissApi.business.lienBusiness({
          reason: lienForm?.reason ?? "",
          business_id: String(biz.id),
          amount: lienForm?.amount ?? 0,
          user_pin: lienForm?.user_pin ?? "",
        })
      );
    }

    if (resp.payload?.status === "success") {
      fetchData();

      setModal({
        block: false,
      });
    }
  }

  async function handleDeduction() {
    if (!deductForm.reason || deductForm?.amount === null || deductForm.amount < 1) {
      if (!deductForm?.reason || deductForm?.reason?.length < 3) {
        setErrors({ reason_for_deduction: "This field is required" });
      }
      if (deductForm?.amount === null || deductForm.amount < 1) {
        setErrors({ amount: "This field is required" });
      }
      return;
    }

    const resp = await dispatch(
      BugissApi.business.deductABusiness({
        // ...deductForm,
        amount: deductForm.amount,
        business_id: String(biz.id),
        user_pin: deductForm.user_pin,
        reason: deductForm.reason,
        business_account_number: deductForm.business_account_number
          .value as unknown as any,
      })
    );

    if (resp.payload?.status === "success") {
      fetchData();
      setDeductForm({
        amount: null,
        business_id: "",
        business_account_number: "",
        reason: "",
        user_pin: 0,
      });

      setModal({
        block: false,
      });
    }
  }

  useEffect(() => {
    if (tab === "bulk-transfer-details") {
      const foundType: any = {
        compoundName: "transfer",
        value: "bulk-transfer-details",
        label: "Bulk Transfer Details",
      };

      if (foundType) {
        setType(foundType);
      } else {
        // Handle case where tab is not found
      }
    } else {
      let foundType: any = "";
      let found: any = Tabs.find((t: any) => {
        if (t.dropdown && t.dropdown.tabs) {
          // Check if dropdown exists and has tabs array
          foundType = t.dropdown.tabs.find((d: any) => d.value === tab);

          return foundType; // Return the matching tab if found
        } else if (t.value === tab) {
          return true;
        } else return false;
      });

      if (foundType) {
        setType(foundType);
      } else {
        if (found) {
          setType(found);
        }
      }
    }
  }, [tab, bulk_ref]);

  useEffect(() => {
    setDeductForm({
      ...deductForm,
      business_id: biz.id ?? 0,
      business_account_number: biz.initial_account_number_generation ?? "",
    });
  }, []);

  const { business_compliances } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  const { loading: cardLoading } = useSelector((state: RootState) => state.businessCards);
  type Verifications = BusinessComplianceModel["business_verification_details"];

  const compliance =
    business_compliances?.data?.business_verification_details;

  useEffect(() => {
    if (type.value === "compliance" && email) {
      dispatch(
        BugissApi.business.fetchCompliance({
          business_email: email,
        })
      );
    }
  }, [type.value, email]);

  async function fetchBankAccounts() {
    const resp = await dispatch(
      BugissApi.business.getBizBankAccounts({
        business_id: String(biz.id),
      })
    );

    if (resp.payload?.status === "success") {
      const selectData = resp.payload.data?.bank_account.data.map((d: any) => {
        return {
          label: d.account_name,
          value: d.account_number,
          bal: d.balance,
        };
      });

      setBankAccounts(selectData);
    }
  }

  const navigate = useNavigate();

  function handleCardDestroy() {
    dispatch(
      BugissApi.business.freezeBusinessCard({
        business_id: biz.id,
        card_id: String(selectedCard?.id),
      })
    );
  }
  function handleCardFreeze() {
    dispatch(
      BugissApi.business.unfreezeBusinessCard({
        business_id: biz.id,
        card_id: String(selectedCard?.id),
      })
    );
  }

  const reasonReq = businessReduxAPI.useFetchReasonsQuery(
    { type: "block_business" },
    { skip: !modal?.block }
  );

  const bloc = reasonReq?.data?.reasons ?? [];

  function formatDeclineReason() {
    return bloc.map((reason: { reason: string; id: any; }) => {
      return {
        label: Util.cleanText(reason?.reason),
        value: reason?.id,
      };
    });
  }
  // console.log(bloc.data, "block reason");

  async function fetchBlockReasons() {
    const resp = await dispatch(BugissApi.business.fetchBlockReasons({}));

    if (resp.payload) {
      setServerBlockReasons(
        resp.payload?.length > 0 &&
          resp.payload?.map((d: string) => {
            return {
              label: Util.cleanText(d),
              value: d,
            };
          })
      );
    }
  }

  useEffect(() => {
    fetchBlockReasons();
  }, []);

  const { toggleModal, exportValue, clearExportValue } = useExportStatement(
    statementFunc ? handleExportStatement : () => {}
  );

  const res = businessReduxAPI.useGetAllSubAccountsQuery({
    business_id: String(biz?.id),
  });

  const subAcc = res.currentData?.bank_account;

  async function handleExportStatement() {
    const exportVal: any = exportValue;
    const resp = await dispatch(
      BugissApi.business.generateStatement({
        business_id: biz.id as string,
        send_to: "download",
        currency: "NGN",
        file_type: exportValue.fileType,
        date: `${exportValue.start_date.replaceAll(
          "/",
          "-"
        )}_${exportValue.end_date.replaceAll("/", "-")}`,
        account_id: exportVal.business_account_number.id,
      })
    );

    if (resp.payload?.status === "success") {
      const url = resp.payload.data.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Statement of Account #${biz.id}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      clearExportValue();
      toggleModal(false);

      setStatementFunc(false);
    }
  }

  const authorizedDevices: IDevices[] =
    biz?.authorised_devices?.data && biz?.authorised_devices?.data?.length > 0
      ? biz?.authorised_devices?.data.map((d) => JSON.parse(d.fingerprint))
      : [];

  const beneficiaries = biz?.beneficiaries?.data ?? [];

  const { data, isFetching, isLoading } = businessReduxAPI.useGetOperationStaffsQuery(
    {},
    { skip: !modal.managers }
  );

  const hniDetailReq = businessReduxAPI.useGetHniBusinessesQuery({
    business_email: bizEmail,
  });

  const hniDetail = hniDetailReq?.data?.data[0]?.hni_managers_details;

  const hniOps = data?.operation_staffs;
  const opsLoading = isFetching || isLoading;
  const [managerValue, setManagerValue] = useState({});
  const [bizManagers, setBizManagers] = useState<Partial<HNIOperators[]>>([]);
  const [isAddn, setIsAddn] = useState(false);
  const [linkedBiz, setLinkedBiz] = useState<Partial<LinkedBusinessShape>>();

  useEffect(() => {
    hniDetailReq.refetch();
  }, [hniOps]);

  useEffect(() => {
    const nis = hniOps?.filter((d: { email: any; }) =>
      hniDetail?.map((d: { email: any; }) => d.email).includes(d.email)
    ) as HNIOperators[];

    setBizManagers(nis);
  }, [hniDetail, hniDetailReq]);

  function addManagerToList(e: HNIOperators) {
    const exists = bizManagers.find((el) => el?.id === e.id);

    if (!exists) {
      setBizManagers((prev) => [...prev, e]);
    }
  }

  function removeManagerToList(e: HNIOperators) {
    const newOps = bizManagers.filter((el) => el?.id !== e.id) as HNIOperators[];

    setBizManagers(newOps);
  }

  async function handleAddManager() {
    setIsAddn(true);

    let resp;

    resp = await sendRequest("business_management/assign_managers", {
      business_id: biz.id?.toString(),
      selected_managers: bizManagers.map((d) => d?.email),
    });

    if (resp?.status === "success") {
      setModal({
        managers: false,
      });

      toast.success(resp?.message);
      setIsAddn(false);
    } else {
      setIsAddn(false);
      toast.success(resp?.message);
    }
  }


  async function fetchLinkedBusiness() {


    let resp;

    resp = await sendRequest(`businesses/get-all-linked-businesses?created_by_email=${biz.email ?? email}`, {}, {method: 'GET'});

    if (resp?.status === "success") {
      setLinkedBiz(resp?.message as unknown as LinkedBusinessShape);
    } else {

      // toast.success(resp?.message);
    }
  }

  useEffect(() => {
    fetchLinkedBusiness()
  }, [email, biz.email])

  return (
    <>
      <DashboardLayout>
        <PageLayout
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "unset", padding: 0 }}
          topRightContent={
            <RavenButton
              onClick={() => setModal({ managers: true })}
              color="black-light"
              label="Assign a Manager"
            />
          }
          pageTitle="Business Details"
        >
          <div style={{ height: actionBar ? "" : 0 }}>
            <RavenActionBar
              actionText="This business is currently blocked and inactive"
              visible={biz.block_business === 1 ? true : false}
              type="warning"
              className="biz__actionbar non-mobile"
              onAction={() => {}}
              onCancel={() => setActionBar(false)}
            />
          </div>
          <div className=" single-business-details">

            <div className={"single-business-linked-wrapper"}>
              <div className="single-business-details__top-wrap">
                <div className="top-wrap__main-content">
                  <div className="top-wrap__main-content--profile-img">
                    {icons.bank_profile}
                    <div className="top-wrap__main-content--profile-img__user-status">
                      {biz?.verification_status !== "verified"
                        ? icons.slash_circle_01
                        : icons.check_verified}
                      <p>
                        {biz?.verification_status === "verified"
                          ? "Verified Business"
                          : "Unverified Business"}
                      </p>
                    </div>
                  </div>

                  <div className="top-wrap__main-content--user-data">
                    <div className="user-data__main-content">
                      <div className="main-content__col">
                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Business Name</small>
                              <p>{biz?.business_name ?? "--"}</p>
                            </>
                          )}
                        </div>

                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Date Joined</small>
                              <p>{formatDateTime(biz?.created_at ?? "")}</p>
                            </>
                          )}
                        </div>

                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Total Referral Earned</small>
                              <span>
                              <p>0.00</p>
                            </span>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="main-content__col">
                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Owner Fullname</small>
                              <p>{Util.safeValue(biz?.buiness_owner_fullname)}</p>
                            </>
                          )}
                        </div>

                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Last Seen</small>
                              <p>{formatDateTime(biz.last_seen ?? "")}</p>
                            </>
                          )}
                        </div>

                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Authorised Devices</small>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  authorizedDevices.length > 0 &&
                                  setModal({ devices: true });
                                }}
                              >
                              <p>
                                {biz.number_of_authorised_devices !== 0
                                  ? biz.number_of_authorised_devices
                                  : "No Devices Yet"}
                              </p>
                              <figure>{icons.arrow_circle_right}</figure>
                            </span>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="main-content__col">
                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Email Address</small>
                              <p>{biz?.business_owner_email}</p>
                            </>
                          )}
                        </div>

                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Verification Status</small>
                              <p
                                className={
                                  Number(biz.verification_details?.total_verified) > 4
                                    ? "verified"
                                    : "unverified"
                                }
                              >
                                {biz.verification_details?.total_verified +
                                  " of " +
                                  biz.verification_details?.total_verifications}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Beneficiary Accounts</small>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  beneficiaries.length > 0 &&
                                  setModal({ beneficiary: true });
                                }}
                              >
                              <p>
                                {(biz.beneficiaries && biz.beneficiaries.data?.length) ??
                                  0}
                              </p>
                              <figure>{icons.arrow_circle_right}</figure>
                            </span>
                            </>
                          )}
                        </div>
                        {/* <div className="main-content__col--item">
                        {bizLoading ? (
                          <Redacted.Labelled />
                        ) : (
                          <>
                            <small>Fullname</small>
                            <span>
                              <p>
                                {Util.join(Util.safeValue(biz.fname)).with(
                                  Util.safeValue(biz.lname)
                                )}
                              </p>
                              <figure>{icons.arrow_circle_right}</figure>
                            </span>
                          </>
                        )}
                      </div> */}
                      </div>

                      <div className="main-content__col">
                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Mobile Number</small>
                              <p>{Util.safeValue(biz.phone)}</p>
                            </>
                          )}
                        </div>

                        <div className="main-content__col--item">
                          {bizLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <>
                              <small>Sub Accounts</small>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  Number(biz?.total_sub_accounts) > 0 &&
                                  setModal({ subAccount: true });
                                }}
                              >
                              <p>{Util.safeValue(biz.total_sub_accounts)}</p>
                              <figure>{icons.arrow_circle_right}</figure>
                            </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="user-data__account-summary">
                      <div className="user-data__account-summary--item">
                        {bizLoading ? (
                          <Redacted.Labelled secondary />
                        ) : (
                          <>
                            <p>Business Balance</p>
                            <h6>
                              ₦{formatNumWithComma(biz?.business_balance ?? "0", "ngn")}
                            </h6>
                          </>
                        )}
                      </div>

                      <div className="user-data__account-summary--item">
                        {bizLoading ? (
                          <Redacted.Labelled secondary />
                        ) : (
                          <>
                            <p>Overdraft</p>
                            <h6>{`₦0`}</h6>
                          </>
                        )}
                      </div>

                      <div className="user-data__account-summary--item">
                        {bizLoading ? (
                          <Redacted.Labelled secondary />
                        ) : (
                          <>
                            <p style={{ color: "#FF0F00" }}>Lien</p>
                            <h6>{formatNumberToCurrency(Number(biz.lien), "NGN")}</h6>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="top-wrap__actions">
                  <RavenButton
                    onClick={() => {
                      setModal({
                        block: true
                      });
                    }}
                    className="block-user-btn"
                    color="white-light"
                  >
                    <figure>{icons.slash_circle_01}</figure>
                    <p>
                      {biz.block_business === 0 ? "Block Business" : "Unblock Business"}
                    </p>
                  </RavenButton>

                  <RavenButton
                    className="lien-user-btn"
                    onClick={() => {
                      setModal({
                        lien: true
                      });
                    }}
                    color="white-light"
                  >
                    <figure>{icons.info_circle}</figure>
                    <p>Lien Amount</p>
                  </RavenButton>

                  <RavenButton
                    onClick={() => {
                      setModal({
                        deduct: true
                      });
                      fetchBankAccounts();
                    }}
                    className="make-deduction-btn"
                    color="white-light"
                  >
                    <figure>{icons.minus_circle}</figure>
                    <p>Make a Deduction</p>
                  </RavenButton>

                  <RavenButton
                    onClick={() => {
                      toggleModal("Generate Statement", { biz: biz?.id });
                      setStatementFunc(true);
                    }}
                    className="strip-account-btn"
                    color="white-light"
                  >
                    <figure>{icons.generate_reciept}</figure>
                    <p>Generate Statement</p>
                  </RavenButton>
                </div>
              </div>

              {linkedBiz?.count && linkedBiz?.count > 0 &&
              <div className={"linked-business-container"}>
               <Row ai={'center'} gap={0.5}>
                 {React.cloneElement(<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                     <path fill-rule="evenodd" clip-rule="evenodd"
                           d="M11.0002 19.8002C15.8603 19.8002 19.8002 15.8603 19.8002 11.0002C19.8002 6.14009 15.8603 2.2002 11.0002 2.2002C6.14009 2.2002 2.2002 6.14009 2.2002 11.0002C2.2002 15.8603 6.14009 19.8002 11.0002 19.8002ZM12.1002 6.6002C12.1002 5.99268 11.6077 5.5002 11.0002 5.5002C10.3927 5.5002 9.9002 5.99268 9.9002 6.6002C9.9002 7.20771 10.3927 7.7002 11.0002 7.7002C11.6077 7.7002 12.1002 7.20771 12.1002 6.6002ZM12.1002 10.4502C12.1002 9.84268 11.6077 9.3502 11.0002 9.3502C10.3927 9.3502 9.9002 9.84268 9.9002 10.4502V14.8502C9.9002 15.4577 10.3927 15.9502 11.0002 15.9502C11.6077 15.9502 12.1002 15.4577 12.1002 14.8502V10.4502Z"
                           fill="url(#paint0_diamond_16246_98938)" />
                     <defs>
                       <radialGradient id="paint0_diamond_16246_98938" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                       gradientTransform="translate(7.87762 19.8002) rotate(-66.1645) scale(25.2246 191.109)">
                         <stop stop-color="#676767" />
                         <stop offset="1" stop-color="#1C2127" />
                       </radialGradient>
                     </defs>
                   </svg>
                 )}

                 <p>
                   This account seems to be linked to {linkedBiz?.count} other businesses
                 </p>
               </Row>

                <span onClick={() => setModal({linkedBusiness: true})}>
                  <p>View Business</p>
                  {icons.arrow_circle_dark_right}
                </span>
              </div>}
            </div>


            <div className="single-business-details__table-wrap">
              <OutletTab>
                <OutletTab.Tabs
                  className={GENERAL_CLASSES.OUTLET}
                  baseURL={`${"/business-businesses/"}${email}`}
                  tabs={Tabs}
                />
                <OutletTab.Outlet />
              </OutletTab>

              {/* <div>
                {type?.label.toLowerCase() === "compliance" ? (
                  availableComliances.map((chi, idx) => (
                    <ComplianceCards
                      data={{
                        business_email: email,
                        business_details: biz,
                        central_biz: centralBiz,
                        director_details: business_compliances?.data
                          ?.directors_details_object as SingleDirector[],
                        app: "Business Banking",
                        type: chi,
                        veriDetail: detectCompliance(chi.value),
                      }}
                    />
                  ))
                ) : (
                  <TransactionTable
                    loading={loading || tableLoading}
                    trx={trx}
                    onRowClick={(chi: BusinessTransactionData) =>
                      setView({ on: true, data: chi })
                    }
                    businessId={biz.id}
                    onCardItemClick={(e: any) => {
                      if (e.action === "freeze") {
                        setModal({
                          cardFreeze: true,
                        });
                        setSelectedCard(e.data);
                      }

                      if (e.action === "destroy") {
                        setModal({
                          cardDestroy: true,
                        });
                        setSelectedCard(e.data);
                      }

                      if (e.action === "view") {
                        navigate(
                          `/business-cards/${e.data.id}?biz=${e.data.business_id}`
                        );
                      }
                    }}
                    email={email}
                    bulk_ref={bulk_ref}
                    type={type}
                  />
                )}
              </div> */}
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={modal?.block as boolean}
        onCancel={() => {
          setModal({ block: false });
        }}
        loading={loading}
        onClick={() => {
          handleBlockUnblock();
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Yes, ${Number(biz?.block_business) === 1 ? "Unblock" : "Block"}`}
        btnColor={"red"}
        body={`${
          Number(biz?.block_business) === 1
            ? "Unblock this business"
            : "Block this business"
        }`}
        title={Number(biz?.block_business) === 1 ? "Unblock User" : "Block User"}
      >
        <p style={{ marginBottom: "2rem" }}>
          {`${
            Number(biz?.block_business) === 1
              ? "Unblock this business"
              : "Block this business"
          }`}
        </p>

        {Number(biz?.block_business) === 0 && (
          <Column>
            <RavenInputField
              color={"black-light"}
              value={blockReason.reason}
              style={{ zIndex: "100000" }}
              className="bugiss-block-modal"
              label="Reason for Block*"
              onChange={(e: any) =>
                setBlockReason((prev: any) => {
                  return {
                    ...prev,
                    reason: e
                  };
                })
              }
              placeholder="Reason for block"
              selectOption={
                formatDeclineReason()?.length === 0
                  ? REASONS_FOR_BLOCK
                  : formatDeclineReason()
              }
              // selectOption={[{ value: "Unblock", label: "din" }]}
              type="select"
            />
            <RavenInputField
              style={{ zIndex: "0" }}
              color={"black-light"}
              value={blockReason?.more_details}
              // className="bugiss-block-modal"
              label="More Details"
              onChange={(e: any) =>
                setBlockReason((prev: any) => {
                  return {
                    ...prev,
                    more_details: e.target.value
                  };
                })
              }
              placeholder="Reason for block"
              type="textarea"
            />
          </Column>
        )}
      </ActionModal>

      <ActionModal
        visible={modal?.lien as boolean}
        onCancel={() => {
          setModal({ lien: false });
        }}
        loading={loading}
        onClick={() => {
          handleLienUnlien();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${Number(biz?.lien) > 0 ? "Unlien" : "Lien"}`}
        btnColor={"red"}
        body={`${
          Number(biz?.block_business) === 1
            ? `Are you sure to lien this Business account? You can always come back to perform the action.`
            : `Are you sure to unlien this Business account? You can always come back to perform the action.`
        }`}
        title={
          Number(biz?.lien) > 0 ? "Unlien Business Account" : "Lien Business Account"
        }
      >
        <p style={{ marginBottom: "2rem" }}>
          {`${
            Number(biz?.lien) > 0
              ? `Are you sure to unlien this Business account? You can always come back to perform the action.`
              : `Are you sure to lien this Business account? You can always come back to perform the action.`
          }`}
        </p>

        <RavenInputField
          color={"black-light"}
          value={lienForm.amount}
          className="bugiss-block-modal"
          label="Amount*"
          showError={errors.amount ? true : false}
          errorText={errors.amount}
          onChange={(e: any) =>
            setLienForm({
              ...lienForm,
              amount: e.target.value
            })
          }
          placeholder="Amount to lien.."
          type="number"
        />

        {!biz.lien && (
          <>
            <Gap v={10} />
            <RavenInputField
              color={"black-light"}
              value={lienForm.reason}
              className="bugiss-block-modal"
              label="Reason for Lien*"
              showError={errors.reason ? true : false}
              errorText={errors.reason}
              onChange={(e: any) =>
                setLienForm({
                  ...lienForm,
                  reason: e.target.value
                })
              }
              placeholder="Reason for Lien"
              type="textarea"
            />
          </>
        )}
        <Gap v={10} />

        <RavenInputField
          color={"black-light"}
          value={lienForm.user_pin}
          className="bugiss-block-modal"
          label="User pin*"
          showError={errors.user_pin ? true : false}
          errorText={errors.user_pin}
          onChange={(e: any) =>
            setLienForm({
              ...lienForm,
              user_pin: e
            })
          }
          placeholder="User pin"
          type="pin"
        />
      </ActionModal>

      <ActionModal
        visible={modal?.deduct as boolean}
        onCancel={() => {
          setModal({ deduct: false });
        }}
        loading={loading}
        onClick={() => {
          handleDeduction();
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Make a Deduction`}
        btnColor={"black"}
        body="Deduct specified amount from user balance"
        title={"Make a Deduction"}
      >
        <p style={{ marginBottom: "2rem" }}>Deduct a certain amount from this business</p>
        <RavenInputField
          color={"black-light"}
          // value={blockReason}
          className="bugiss-block-modal"
          label="Amount*"
          value={deductForm.amount}
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              amount: e.target.value
            })
          }
          showError={errors.amount ? true : false}
          errorText={errors.amount}
          placeholder="Amount*"
          type="number"
        />
        <Gap v={10} />

        <RavenInputField
          color={"black-light"}
          value={deductForm.business_account_number}
          style={{ zIndex: "444" }}
          className="bugiss"
          loadingSelect={loading}
          selectComponents={{ Option: CustomOption }}
          label="Account to deduct*"
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              business_account_number: e
            })
          }
          placeholder="Select a bank account"
          selectOption={bankAccounts ?? []}
          type="select"
        />
        <Gap v={10} />
        <RavenInputField
          color={"black-light"}
          value={deductForm.reason}
          className="bugiss-block-modal"
          label="Reason for Deduction*"
          showError={errors.reason_for_deduction ? true : false}
          errorText={errors.reason_for_deduction}
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              reason: e.target.value
            })
          }
          placeholder="Reason for Deduction"
          type="textarea"
        />
        <Gap v={10} />

        <RavenInputField
          color={"black-light"}
          value={lienForm.user_pin}
          className="bugiss-block-modal"
          label="User pin*"
          showError={errors.user_pin ? true : false}
          errorText={errors.user_pin}
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              user_pin: e
            })
          }
          placeholder="User pin"
          type="pin"
        />

        {/* <RavenInputField
          color={"black-light"}
          value={deductForm.user_pin}
          className="bugiss-block-modal"
          label="User *"
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              user_pin: e.target.value,
            })
          }
          placeholder="Reason for Deduction"
          type="textarea"
        /> */}
      </ActionModal>

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {
        }}
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        hideCancel
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={view?.data?.direction}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {
            }}
            content={
              type.value === "internal-transfer"
                ? [
                  {
                    label: "Amount",
                    value: formatNumberToCurrency(
                      Number(view.data?.amount),
                      view.data?.currency
                    )
                  },
                  { label: "Narration", value: Util.safeValue(view?.data.narration) },
                  {
                    label: "Fee",
                    value: formatNumberToCurrency(
                      Number(view?.data?.fee),
                      view?.data.currency
                    )
                  },
                  {
                    label: "Recipient",
                    value: Util.safeValue(view?.data?.account_name)
                  },
                  {
                    label: "Username",
                    value: cleanWhiteSpace("@", view?.data?.identifier),
                    },
                    { label: "Reference", value: view?.data?.trx_ref },
                    { label: "Session ID", value: view?.data?.session_id },
                    {
                      label: "Status",
                      value: formatStatus(
                        detectStatus(Number(view?.data?.status), "transactions")
                      ) as any,
                    },
                    {
                      label: "Date Logged",
                      value: formatDateTime(String(view?.data?.created_at)),
                    },
                  ]
                : [
                    {
                      label: "Amount",
                      value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
                      // copy: true,
                    },
                    // {
                    //   label: "Business Email",
                    //   value: view.data?.email,
                    // },

                    {
                      label: "Bal Before",
                      value: `₦${formatNumWithComma(view.data.bal_before ?? 0, "ngn")}`,
                    },
                    {
                      label: "Bal After",
                      value: `₦${formatNumWithComma(view.data.bal_after ?? 0, "ngn")}`,
                    },

                    // {
                    //   label: "Narration",
                    //   value: trimLongString(
                    //     formatMetaData(view.data.meta_data ?? "")?.narration,
                    //     30
                    //   ),
                    // },
                    ...renderConditionally(
                      "Narration",
                      trimLongString(
                        formatMetaData(view.data.meta_data ?? "")?.narration,
                        30
                      )
                    ),
                    {
                      label: "Fee",
                      value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
                    },

                    ...renderConditionally(
                      "Recipient",
                      formatMetaData(view.data.meta_data ?? "")?.account_name
                    ),
                    // {
                    //   label: "Recipient",
                    //   value: formatMetaData(view.data.meta_data ?? "")?.account_name,
                    // },

                    {
                      label: "Transaction Ref",
                      value:
                        view.data?.trx_ref ??
                        view.data?.bulk_transfer_reference ??
                        (typeof view?.data?.meta_data === "string"
                          ? JSON.parse(String(view?.data?.meta_data)).session_id
                          : null) ??
                        "--",
                      copy: true,
                      trim: true,
                    },

                    {
                      label: "Status",
                      value: formatStatus(detectStatus(Number(view.data?.status))),
                    },

                    {
                      label: "Date Authorized",
                      value: formatDateTime(view?.data.created_at ?? ""),
                    },
                  ]
            }
          />
        }
      </ActionModal>

      <ActionModal
        visible={modal?.cardFreeze as boolean}
        onCancel={() => {
          setModal({ cardFreeze: false });
        }}
        loading={cardLoading}
        onClick={() => {
          handleCardFreeze();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, Freeze Card`}
        btnColor={"#EA872D"}
        body="Are you sure to freeze this card? you can always come back to this action later."
        title={"Freeze Card?"}
      ></ActionModal>

      <ActionModal
        visible={modal?.cardDestroy as boolean}
        onCancel={() => {
          setModal({ cardDestroy: false });
        }}
        loading={cardLoading}
        onClick={() => {
          handleCardDestroy();
        }}
        cancelText="No, Cancel"
        actionText={`Destroy Card`}
        btnColor={"red"}
        body="Are you sure to destroy this card? This action cannot be undone?"
        title={"Destroy Card?"}
      ></ActionModal>
      <ActionModal
        visible={modal?.devices as boolean}
        onCancel={() => {
          setModal({ devices: false });
        }}
        loading={cardLoading}
        onClick={() => {
          setModal({ devices: false });
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Close`}
        btnColor={"black"}
        subTitle="All Devices Authorized to use this account"
        title={"Authorized Devices"}
      >
        <AuthorizedDevices data={authorizedDevices} />
      </ActionModal>

      <ActionModal
        visible={modal?.beneficiary as boolean}
        onCancel={() => {
          setModal({ beneficiary: false });
        }}
        loading={cardLoading}
        onClick={() => {
          setModal({ beneficiary: false });
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Close`}
        btnColor={"black"}
        subTitle="All beneficiary accounts belonging to this business"
        title={"Beneficiary Account"}
      >
        <BusinessBeneficiary data={beneficiaries} />
      </ActionModal>

      <ActionModal
        visible={modal?.subAccount as boolean}
        onCancel={() => {
          setModal({ subAccount: false });
        }}
        loading={cardLoading}
        onClick={() => {
          setModal({ subAccount: false });
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Close`}
        btnColor={"black"}
        subTitle="All subaccounts belonging to this business"
        title={"Sub Bank Accounts"}
      >
        <BusinessSubaccounts data={subAcc?.data} />
      </ActionModal>

      <ActionModal
        visible={modal?.linkedBusiness as boolean}
        onCancel={() => {
          setModal({ linkedBusiness: false });
        }}
        loading={cardLoading}
        onClick={() => {
          setModal({ linkedBusiness: false });
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Close`}
        btnColor={"black"}
        subTitle="All subaccounts belonging to this business"
        title={"Sub Bank Accounts"}
      >
        <BusinessLinkedBusinesses data={linkedBiz?.linked_businesses as LinkedBusiness[]} />
      </ActionModal>

      <ActionModal
        visible={modal?.managers as boolean}
        onCancel={() => {
          setModal({ beneficiary: false });
        }}
        loading={isAddn}
        onClick={handleAddManager}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Close`}
        btnColor={"black"}
        subTitle="Assign a Manager to this business"
        title={"Assign Manager to Business"}
      >
        <RavenInputField
          color={"black-light"}
          value={managerValue}
          style={{ zIndex: "444" }}
          className="bugiss"
          loadingSelect={opsLoading}
          selectComponents={{ Option: CustomManagerOption }}
          label="Managers Email*"
          onChange={(e: any) => {
            setManagerValue({
              ...managerValue,
              e,
              label: Util.join(e.fname).with(e.lname),
            });
            addManagerToList(e);
          }}
          loading={opsLoading}
          placeholder="Enter managers email"
          selectOption={hniOps}
          type="select"
        />

        <Operators
          data={bizManagers as HNIOperators[]}
          onDelete={(e) => removeManagerToList(e)}
        />
      </ActionModal>
    </>
  );
};

export default BusinessSingleBusiness;

const CustomOption = ({
  innerProps,
  label,
  data,
}: {
  innerProps: any;
  label: string;
  data: any;
}) => {
  return (
    <div
      {...innerProps}
      className={cn("custom-select__bank-account", innerProps.isFocused && "active")}
    >
      <span>{trimLongString(label, 35)}</span>
      <span>
        <p>Bal:</p> {formatNumberToCurrency(data.bal, "NGN")}
      </span>
    </div>
  );
};

const CustomManagerOption = ({
  innerProps,
  data,
}: {
  innerProps: any;
  label: string;
  data: HNIOperators;
}) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      {...innerProps}
      className={cn(innerProps.isFocused && "active")}
    >
      <AvatarWithTwinValue
        name={Util.join(data.fname).with(data?.lname)}
        one={Util.join(data.fname).with(data?.lname)}
        two={Util.safeValue(data?.email)}
      />
      <Gap size={10} />
    </div>
  );
};
function renderConditionally(label: any, data: any) {
  if (Boolean(data)) {
    return [{ label: label, value: data }];
  } else {
    return [];
  }
}
